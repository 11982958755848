import React, { useEffect, useState, useContext, useRef, createRef } from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Lookup,
    Editing,
    Scrolling,
    RequiredRule
} from 'devextreme-react/data-grid';
import TaskDetail from './taskDetails'
import './grid.scss'
import { taskService } from '../../services/task-service'
import CustomStore from 'devextreme/data/custom_store';
import notify from 'devextreme/ui/notify';
import * as TaskEnum from '../../constant/taskEnum';
import * as Constants from '../../constant/appConst';

const SubTask = (props) => {


    const [dataSource, setDataSource] = useState({});

    function isNotEmpty(value) {
        return value !== undefined && value !== null && value !== '';
    }

    const taskDataSource = () => {
        return {
            store: new CustomStore({
                key: 'id',
                load: async (loadOptions) => {
                    let params = '?';
                    [
                        'skip',
                        'take',
                        'requireTotalCount',
                        'requireGroupCount',
                        'sort',
                        'filter',
                        'totalSummary',
                        'group',
                        'groupSummary'
                    ].forEach(function (i) {
                        if (i in loadOptions && isNotEmpty(loadOptions[i])) {
                            params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                        }
                    });
                    params = params.slice(0, -1);
                    let defaultChain = -1;
                    let taskData = await taskService.getTasks(params, defaultChain, props.data.data.id);
                    return {
                        data: taskData.data,
                        totalCount: taskData.totalCount,
                        summary: taskData.summary,
                        groupCount: taskData.groupCount
                    };
                },
                insert: async (values) => {
                    if (values != null) {
                    }
                },

                update: async (key, values) => {
                    values.id = key;
                    if (key > 0) {
                        values.parentId = key;
                        await taskService.updateTask(values);
                        toast("Task is updated ", "success");
                    }
                },
                remove: async (key) => {
                    if (key > 0) {
                        await taskService.deleteTask(key);
                        toast("Task is deleted ", "success");
                    }
                },
            })
        }
    }

    useEffect(() => {
        if (props.data.data.id > 0) {
            setDataSource(taskDataSource());
        }
    }, []);

    const toast = (message, infoType) => {
        notify({
            message: message,
            position: {
                my: 'center top',
                at: 'center top'
            }
        }, infoType, 3000);
    }

    const renderVSOLink = (data) => {
        if (data.data.vsoId) {
            return (<div className="task-grid-button">
                <a href={Constants.apiConst.VSO_LINK + "TouchSoft/_workitems/edit/" + data.data.vsoId} target='_blank' className="p6 cursor-pointer dx-icon-link dx-link-icon" title="Link" aria-label="Link"></a>
            </div>);
        } else {
            return '';
        }
    }


    return (


        <React.Fragment>
            <DataGrid
                className={'dx-card wide-card'}
                dataSource={dataSource}
                keyExpr='id'
                showBorders={false}
                // focusedRowEnabled={true}
                // defaultFocusedRowIndex={0}
                columnHidingEnabled={true}
                remoteOperations={true}
                rowAlternationEnabled={true}
                columnHidingEnabled={true}
                columnAutoWidth={true}
                allowColumnResizing={true}
                columnResizingMode='widget'
                allowColumnReordering={true}
                width='100%'
                height='100%'
                showColumnLines={true}
            >
                <Scrolling mode="infinite" />

                <Editing
                    mode="row"
                    allowUpdating={true}
                    allowDeleting={true}
                />

                {/*
                <Column
                    dataField={'id'}
                    caption={'Id'}
                    allowEditing={false}
                    width={50}
                />
                 */}
                <Column
                    dataField={'area'}
                    caption={'Area'}
                    width='20%'
                />

                <Column
                    dataField={'initiator'}
                    caption={'Initiator'}
                    width='20%'
                />

                <Column
                    dataField={'taskType'}
                    caption={'Type'}
                    width='15%'
                >
                    <Lookup
                        dataSource={TaskEnum.taskTypes}
                        valueExpr={'value'}
                        displayExpr={'text'}
                    />
                    <RequiredRule
                        message={'Pls. select a type'}
                    />
                </Column>
                {/*
                <Column
                    dataField={'status'}
                    caption={'Status'}
                    width={80}
                >
                    <Lookup
                        dataSource={TaskEnum.statusTypes}
                        valueExpr={'value'}
                        displayExpr={'text'}
                    />
                </Column>
                 */}
                <Column
                    dataField={'importance'}
                    caption={'Importance'}
                    dataType='number'
                    width='10%'
                />

                <Column
                    dataField={'priority'}
                    caption={'Priority'}
                    dataType='number'
                    width='10%'
                />

                <Column
                    dataField={'mark'}
                    caption={'Mark'}
                    dataType='boolean'
                    width='20%'
                />

                <Column
                    dataField={'title'}
                    caption={'Title'}
                    width='15%'
                >
                    <RequiredRule
                        message={'Title must be entered'}
                    />
                </Column>

                <Column
                    dataField={'description'}
                    caption={'Description'}
                    width='15%'
                />
                {/*
                <Column
                    dataField={'parentId'}
                    caption={'Parent'}
                    width={50}
                />
                
                <Column
                    dataField={'createdAt'}
                    caption={'Created'}
                    width={80}
                    allowEditing={false}
                    dataType={'date'}
                    format={'dd.MM.yy'}
                />

                <Column
                    dataField={'updatedAt'}
                    caption={'Updated'}
                    width={80}
                    allowEditing={false}
                    dataType={'date'}
                    format={'dd.MM.yy'}
                />
                 */}

                <Column
                    dataField={'solvedAt'}
                    caption={'Solved'}
                    width='15%'
                    dataType={'date'}
                    format={TaskEnum.dateFormat}
                />
                {/*
                <Column
                    cellRender={renderVSOLink}
                    caption={'VsoLink'}
                    width='10%'
                />
                 */}
                <Column
                    dataField={'solvedDescription'}
                    caption={'Solved-description'}
                    width='10%'
                />


            </DataGrid>

        </React.Fragment>
    )
};



export default SubTask;