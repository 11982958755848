import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { getUser, signIn as sendSignInRequest } from '../api/authApi';
import { authenticationService } from '../services/authentication-service';
import { localStorageService } from '../services/localstorage-service';

function AuthProvider(props) {
    const [user, setUser] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async function () {
            const result = await getUser();
            if (result.isOk) {
                setUser(result);
            }

            setLoading(false);
        })();
    }, []);

    const signIn = useCallback(async (email, password) => {

        authenticationService.login(email, password)
            .then(
                user => {
                    var responceUser = {
                        name: user.userName,
                        email: user.email,
                        chain: user.chainID,
                        token: user.tokenString,
                        loginDateTime: new Date(),
                        userRole : user.role
                    };

                    setUser(responceUser);                  
                    localStorageService.setAuthToken(responceUser.token);
                    localStorageService.setEmail(responceUser.email);
                    localStorageService.setLoginTime(responceUser.loginDateTime);
                    localStorageService.setUserRole(responceUser.userRole);

                    return {
                        isOk: true
                    }
                }
            );
    }, []);

    const signOut = useCallback(() => {
        localStorageService.clearStorage();
        setUser();
    }, []);


    return (
        <AuthContext.Provider value={{ user, signIn, signOut, loading }} {...props} />
    );
}

const AuthContext = createContext({});
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth }
