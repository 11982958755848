import React from 'react';
import { localStorageService } from '../../services/localstorage-service';
import { confirm } from 'devextreme/ui/dialog';


export default function TaskCommentInfo(item, setIsLoading, toast, setDataSource, taskCommentService) {
    const onItemDeleting = function (e) {
        let result = confirm("<i>Are you sure you want to delete this message?</i>", "Confirm deletion");
        result.then(async (dialogResult) => {
            if (dialogResult) {
                setIsLoading(true);
                let deletedTaskComment = await taskCommentService.deleteById(item.id);
                if (deletedTaskComment) {
                    setDataSource(prevDataSource => {
                        return prevDataSource.filter(item => item.id !== deletedTaskComment.id);
                    });
                    setIsLoading(false);
                    
                }
            }
        });
    };
    let createdAt = new Date(item.createdAt);
    var dd = String(createdAt.getDate()).padStart(2, '0');
    var mm = String(createdAt.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = createdAt.getFullYear();
    var dateFormat = dd + '.' + mm + '.' + yyyy.toString().substring(2); 
    let htmlText = '<span>' + dateFormat + "&nbsp;" + createdAt.toLocaleString('no-NO', { hour: 'numeric', hour12: false, minute: 'numeric' }) + ':&nbsp;<b>' + item.userName + '</b></span>' + item.text;
    return (
        <div>
            {
                localStorageService.getEmail() == item.email ? <span style={{ float: 'right' }} >
                    {/*<a className="dx-link dx-link-edit dx-icon-edit dx-link-icon" title="Edit"></a>*/}
                    <a className="dx-link dx-link-delete dx-icon-trash dx-link-icon" onClick={onItemDeleting} title="Delete"></a>
                </span> : ""
            }
            <div style={{ whiteSpace: 'normal', textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: htmlText }} />
        </div>
    );
}