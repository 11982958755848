import * as Constants from '../constant/appConst';
import httpService from "./httpService";

export const taskCommentService = {
    get,
    insert,
    deleteById
};

async function get(taskId, chainId) {
    return await httpService.get(Constants.apiConst.ENDPOINT + Constants.apiConst.TASK_Comment + "?taskId=" + taskId + "&chainId=" + chainId, {});
}

async function insert(taskComment) {
    return await httpService.post(Constants.apiConst.ENDPOINT + Constants.apiConst.TASK_Comment, taskComment);
}

async function deleteById(id) {
    return await httpService.delete(Constants.apiConst.ENDPOINT + Constants.apiConst.TASK_Comment + "?id=" + id, {});
}