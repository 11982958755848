import * as Constants from '../constant/appConst'
import httpService from './httpService';


export const userService = {    
    getUsers,
    getUserResources
}
async function getUsers() {
    return await httpService.get(Constants.apiConst.ENDPOINT + Constants.apiConst.USERS, {})
}

async function getUserResources() {
    return await httpService.get(Constants.apiConst.ENDPOINT + Constants.apiConst.USERRESOURCESS, {})
}
