import { localStorageService } from "./localstorage-service";
import notify from 'devextreme/ui/notify';

let httpService = class httpService {
    constructor() {
        this.get = this.get.bind(this);
        this.post = this.post.bind(this);
        this.put = this.put.bind(this);
        this.delete = this.delete.bind(this);
    }

    getAuthOptions() {
        return {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'bearer ' + localStorageService.getAuthToken()
            }
        };
    }

    async get(url, options) {
        options = { ...this.getAuthOptions() };
        options.method = "GET";
        let response = await fetch(url, options);
        return this.handleResponse(response);
    }

    async post(url, body, options) {
        options = { ...this.getAuthOptions() };
        options.method = "POST";
        if (body) {
            options.body = JSON.stringify(body);
        }
        let response = await fetch(url, options);
        return this.handleResponse(response);
    }


    async put(url, body, options) {
        options = { ...this.getAuthOptions() };
        options.method = "PUT";
        if (body) {
            options.body = JSON.stringify(body);
        }
        let response = await fetch(url, options);
        return this.handleResponse(response);
    }

    async delete(url, body, options) {
        options = { ...this.getAuthOptions() };
        options.method = "DELETE";
        if (body) {
            options.body = JSON.stringify(body);
        }
        let response = await fetch(url, options);
        return this.handleResponse(response);
    }

    async handleResponse(response) {
        return response.text().then(text => {
            const data = text && JSON.parse(text);
            if (!response.ok) {
                if (response.status === 401) {
                    localStorageService.clearStorage();
                    window.location.reload(true);
                }
                const error = (data && data.message) || response.statusText;
                this.toast(error, 'warning');
                return Promise.reject(error);
            }
            return data;
        });
    }

    toast(message, infoType) {
        notify({
            message: message,
            position: {
                my: 'center top',
                at: 'center top'
            }
        }, infoType, 3000);
    }
}

export default httpService = new httpService();