export const apiConst = {
    ENDPOINT: window.location.origin + "/api/",
    // ENDPOINT: "https://localhost:5001/api/",
    LOGIN: "Login/Login",
    TASK: "Task",
    TASK_ALL: "Task/TaskDx",
    TASK_ACTIVE: "Task/TaskActive",
    ADD_TASK: "Task/Insert",
    VSTS_USERS: "Task/team-members",
    REMOVE_TASK: "Task/{taskId}",
    REMOVE_TASK_FROM_GANTT: "Task/DeleteFromGantt/{taskIdForGantt}",    
    UPDATE_TASK: "Task/Update",
    TASK_BY_TASKID: "Task/GetTask/{taskId}",
    VSO_LINK: "https://dev.azure.com/touchsoft/",
    CHAINS: "Chain/chains",
    ADD_VSO: "Task/VSO",
    WORK_TIMESHEET: "WorkTimesheet",
    WORK_TIMESHEET_TASK: "WorkTimesheet/ByTask",
    REMOVE_WORK_TIMESHEET: "WorkTimesheet/{workId}",  
    USERS: "User/Users",
    USERRESOURCESS: "User/UserResourcess",
    TASK_Comment: "taskcomment",
    TASKASSIGNMENT_GET_BY_DATE: "TaskAssignment/GetByDate",
    TASKASSIGNMENT_CREATE: "TaskAssignment/Insert",
    TASKASSIGNMENT_UPDATE: "TaskAssignment/Update",

}
