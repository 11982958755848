export const taskTypes = [
    { text: 'Feil', value: 1 },
    { text: 'Ønske', value: 2 },
    { text: 'Prosjekt', value: 3 },
    { text: 'Annet', value: 4 },
    { text: 'Enkelt', value: 5 },
    { text: 'Krav', value: 6 },
    { text: 'n/a', value: 7 },
    { text: 'Oppgave', value: 8 }]

export const statusTypes = [
    { text: 'Avventes', value: 1 },
    { text: 'Duplikat', value: 2 },
    { text: 'Henlagt', value: 3 },
    { text: 'Uaktuelt nå', value: 4 },
    { text: 'Løst', value: 5 },
    { text: 'Må konkretiseres', value: 6 }]


export const colorsTypes = [
    { text: 'Blue', value: 1 },
    { text: 'Green', value: 2 },
    { text: 'Grey', value: 3 },
    { text: 'None', value: 4 }]

    export const branchTypes = [
        { text: 'Beta', value: 1 },
        { text: 'Development', value: 2 },
        { text: 'Development-Ex', value: 3 },
        { text: 'Production', value: 4 }]

export const dateFormat = 'dd.MM.yy';