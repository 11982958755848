export const navigation = [
  {
    text: 'Home',
    path: '/home',
    icon: 'home'
  },
  {
    text: 'Tasks',
    icon: 'checklist',
    items: [
      {
        text: 'Tasks',
        path: '/tasks'
      },
      {
        text: 'Gantt',
        path: '/gantt'
      },    
      {
        text: 'Scheduler',
        path: '/scheduler'
      },    
    ]}

  // {
  //   text: 'Examples',
  //   icon: 'folder',
  //   items: [
  //     {
  //       text: 'Profile',
  //       path: '/profile'
  //     },
  //     {
  //       text: 'Tasks',
  //       path: '/tasks'
  //     },
  //     {
  //       text: 'Tasks (old)',
  //       path: '/tasksOld'
  //     }
  //   ]
  // }
  ];
