import React, { useState, useEffect, useContext, createRef, useRef } from 'react';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Lookup,
    Editing,
    Scrolling,
    FilterPanel,
    Sorting,
    HeaderFilter,
    Summary,
    TotalItem,
    RequiredRule,
    ColumnChooser,
    StateStoring
} from 'devextreme-react/data-grid';
import { Accordion, CheckBox, LoadPanel, ScrollView, Slider, NumberBox } from 'devextreme-react';
import { workTimesheetService } from '../../services/work-timesheet-service';
import notify from 'devextreme/ui/notify';
import * as TaskEnum from '../../constant/taskEnum';
import CustomStore from 'devextreme/data/custom_store';
import { taskService } from '../../services/task-service';
import { userService } from '../../services/user-service';

const WorkTimesheet = (props) => {


    const [isWorkTimesheetLoading, setIsWorkTimesheetLoading] = useState(false);
    const [dataSource, setDataSource] = useState({});
    const [percentFinish, setPercentFinish] = useState(0);
    const [taskUsers, setTaskUsers] = useState([]);
    const timeCellRender = { type: 'time' };
    let defaultTimeSpent = new Date();
    defaultTimeSpent.setHours(0);
    defaultTimeSpent.setMinutes(0);
    let workLoaderPosition = { of: "#gridContainer-workhour" };

    function isNotEmpty(value) {
        return value !== undefined && value !== null && value !== '';
    }

    useEffect(() => {
        if (props.properties != null && props.properties.task) {
            //if (props.properties.workTimesheetPopUp)
            //setIsWorkTimesheetLoading(true);

            getUsers();
            setPercentFinish(props.properties.task.percentFinish);
            setDataSource(workHourDataSource(props.properties.task.id));
        }
    }, []);

    const getUsers = async () => {
        let users = await userService.getUsers();
        setTaskUsers(users);
    }

    const dateConversionIntoTime = (timeSpentDate) => {
        let time = 0;
        if (typeof (new Date(timeSpentDate)) === 'object') {
            let dateTimeSpent = new Date(timeSpentDate);
            var hour = dateTimeSpent.getHours();
            var minute = dateTimeSpent.getMinutes();
            if (hour > 0) {
                time = hour * 3600;
            }
            if (minute > 0) {
                time += minute * 60;
            }
        }
        return time;
    }

    const filterTimeSpentOperations = ['>=', '<='];

    const gridColumnCustomFilter = (item) => {
        if (item == null) {
            return null;
        }

        item[0] = item[0].replace("timeSpentDate", "timeSpent");
        item[2] = dateConversionIntoTime(item[2]);
        return item;
    }

    const workHourDataSource = (taskId) => {
        return {
            store: new CustomStore({
                key: 'id',
                load: async (loadOptions) => {
                    let params = '?';
                    [
                        'skip',
                        'take',
                        'requireTotalCount',
                        'requireGroupCount',
                        'sort',
                        'filter',
                        'totalSummary',
                        'group',
                        'groupSummary'
                    ].forEach(function (i) {
                        if (i in loadOptions && isNotEmpty(loadOptions[i])) {

                            if (i == "filter") {
                                let single = 0;

                                if (loadOptions.filter.columnIndex != null) {
                                    if (loadOptions.filter.findIndex(x => x === 'timeSpentDate') != -1) {
                                        let item = loadOptions.filter;
                                        item = gridColumnCustomFilter(item);
                                        single = 1;
                                    } else {
                                        single = 0;
                                    }
                                }

                                if (single == 0) {
                                    // Loop to display the elements of 2D array.                              
                                    loadOptions.filter.forEach(function (item, index) {
                                        let itemsLength = loadOptions.filter.length;
                                        if (itemsLength % 2 != 0) {
                                            if (typeof (item) !== 'number') {

                                                var isNumberFilter = item[0].indexOf("timeSpentDate");
                                                if (isNumberFilter != -1 && loadOptions.filter.length > 1) {
                                                    if (item.filterValue !== undefined) {
                                                        item = gridColumnCustomFilter(item);
                                                    }
                                                }
                                            }
                                        }
                                    });

                                    if (loadOptions.filter.columnIndex == null) {
                                        if (loadOptions.filter.length === 1) {
                                            loadOptions.filter = loadOptions.filter[0];
                                        }
                                    }
                                }
                            }

                            params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                        }
                    });
                    params = params.slice(0, -1);
                    let taskData = await workTimesheetService.getWorkHour(params, taskId);
                    setIsWorkTimesheetLoading(false);
                    return {
                        data: taskData.data,
                        totalCount: taskData.totalCount,
                        summary: taskData.summary,
                        groupCount: taskData.groupCount
                    };
                },
                insert: async (values) => {
                    if (values != null) {
                        values.taskId = taskId;
                        values.timeSpent = dateConversionIntoTime(values.timeSpentDate);
                        if (values.workDate != null && values.workDate != undefined)
                            values.workDateString = new Date(values.workDate).toString()
                        let taskData = await workTimesheetService.addWorkHour(values);
                        toast("Work is created", "success");

                    }
                },

                update: async (key, values) => {
                    values.id = key;
                    if (key > 0) {
                        if (values.timeSpentDate != null) {
                            values.timeSpent = dateConversionIntoTime(values.timeSpentDate);
                        }

                        await workTimesheetService.updateWorkHour(values);
                        toast("Work is updated ", "success");
                    }
                },
                remove: async (key) => {
                    if (key > 0) {
                        await workTimesheetService.deleteWorkHour(key);
                        toast("Work is deleted ", "success");
                    }
                },
            })
        }
    }


    const toast = (message, infoType) => {
        notify({
            message: message,
            position: {
                my: 'center top',
                at: 'center top'
            }
        }, infoType, 3000);
    }


    const handleOnProgressChange = async (value) => {

        if (value.event != null) {

            //setLoading(true);
            setTimeout(async () => {
                var task = {
                    id: props.properties.task.id,
                    percentFinish: value.value
                }

                await taskService.updateTask(task);
                setPercentFinish(value.value);
                //setLoading(false);
                toast("Updated ", "success");
            }, 1000)
        }
    }


    const formatSlider = (value) => {
        return `${value}%`;
    }

    const customizeDate = (data) => {
        return `Total Time : ${(data.value)}`;
    }

    return (
        <React.Fragment>

            <LoadPanel
                visible={isWorkTimesheetLoading}
                shading={true}
            />

            <ScrollView
                id="scrollview1"
                scrollByContent={true}
                bounceEnabled={false}
                showScrollbar={'always'}
                scrollByThumb={true}
            >

                <DataGrid
                    id="gridContainer-workhour"
                    dataSource={dataSource}
                    keyExpr={'id'}
                    showBorders={true}
                    remoteOperations={true}
                    width='100%'
                    height='100%'
                    allowColumnResizing={true}
                    allowColumnReordering={true}
                >

                    <FilterRow visible={true} />

                    <Editing
                        mode="row"
                        allowAdding={true}
                        allowUpdating={true}
                        allowDeleting={true}
                    />
                    <ColumnChooser enabled={true} />
                    <StateStoring enabled={true} type="localStorage" storageKey="workTimesheet-gridList" />
                    <Scrolling mode="infinite" />
                    <Paging pageSize={20} />

                    <Column
                        dataType={'date'}
                        format={TaskEnum.dateFormat}
                        dataField={'workDate'}
                        caption={'Date'}
                        width={100}
                    >
                        <RequiredRule
                            message={'Please select a date'}
                        />
                    </Column>

                    <Column
                        dataField={'userId'}
                        caption={'User'}
                        width={120}>

                        <Lookup
                            dataSource={taskUsers}
                            valueExpr={'id'}
                            displayExpr={'text'}
                        />
                    </Column>

                    <Column
                        alignment={'left'}
                        caption={'Time Spent'}
                        width={170}
                        dataField={'timeSpentDate'}
                        dataType={'datetime'}
                        editorOptions={timeCellRender}
                        format='HH:mm'
                        value={defaultTimeSpent}
                        filterOperations={filterTimeSpentOperations}

                    />

                    <Column
                        alignment={'left'}
                        dataField={'description'}
                        caption={'Description'}
                        width={300}>
                        <RequiredRule
                            message={'Fill description'}
                        />
                    </Column>


                    <Summary>
                        <TotalItem
                            cssClass={'heightGridCell'}
                            showInColumn={'timeSpentDate'}
                            summaryType="custom"
                            customizeText={customizeDate} />
                    </Summary>


                </DataGrid>

                <div className="dx-field">
                    <div className="dx-field-label">Progress</div>
                    <div className="dx-field-value">

                        <Slider min={0} max={100}
                            onValueChanged={handleOnProgressChange}
                            value={percentFinish}
                            step={10}
                            tooltip={{
                                enabled: true,
                                showMode: 'always',
                                position: 'top',
                                format: formatSlider
                            }} />
                    </div>
                </div>

            </ScrollView>

        </React.Fragment>
    )
}

export default WorkTimesheet;
