import notify from 'devextreme/ui/notify';

export function toast(message, infoType) {
    notify({
        message: message,
        position: {
            my: 'center top',
            at: 'center top'
        }
    }, infoType, 6000);
}