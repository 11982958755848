import React from 'react';

export default function Appointment(model) {
  const { appointmentData } = model.data;

  function hexToRgbA(hex){
    var c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length== 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',0.7)';
    }
    throw new Error('Bad Hex');
}

var color = hexToRgbA(appointmentData.color)

  return (
    <div style={{backgroundColor: color, color: 'white', width:'100%', height:'100%', padding:'4px', margin:'0', borderRadius: '4px',   }}>
        {appointmentData.text}
    </div>
  );
}
