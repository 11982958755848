import React, { useState, useEffect, createRef } from "react";
import Scheduler, {
  Resource,
  View,
  Scrolling,
  AppointmentDragging,
} from "devextreme-react/scheduler";
import Draggable from 'devextreme-react/draggable';
import ScrollView from 'devextreme-react/scroll-view';
import './scheduler.css'
import { userService } from '../../../services/user-service';
import { taskService } from '../../../services/task-service'
import { taskAssignmentService } from '../../../services/taskAssignment-service'
import Appointment from './appointment.js';
import ContextMenu from 'devextreme-react/context-menu';
import { AppointmentMenuTemplate } from "./contextMenu";
import { toast } from '../../../common/common'
import { Button } from 'devextreme-react/button';
import notify from 'devextreme/ui/notify';


const currentDate = new Date();
const groups = ["id", "parentId"];
const startDay = new Date(2021, 1, 1);
const endDay = new Date(2021, 1, 5);
const startDayHour = 8;
const endDayHour = 16;
const draggingGroupName = 'appointmentsGroup';
const appointmentClassName = '.dx-scheduler-appointment';
const priorityGroups = ['id'];
const holidays = [
  new Date(2022, 6, 16),
  new Date(2022, 6, 19),
];
const dinnerTime = { from: 12, to: 13 };

const notifyDisableDate = () => {
  notify('Cannot create or move an appointment/event to disabled time/date regions.', 'warning', 2000);
};

const schedulerRef = createRef()

const editOptions = {
  allowAdding: true,
  allowDeleting: true,
  allowResizing: true,
  allowDragging: true,
  allowUpdating: true,
}

function App() {
  const [appointments, setAppointments] = useState([]);
  const [resources, setResources] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [parentTasks, setParentTasks] = useState([]);
  const [contextMenuItems, setContextMenuItems] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [target, setTarget] = useState(appointmentClassName);
  const [undoList, setUndoList] = useState({ list: [], position: 0 });
  const [redoList, setRedoList] = useState({ list: [], position: 0 });

  useEffect(() => {
    var tempApps = []
    var tempResources = []

    userService.getUserResources()
      .then((data) => {
        tempResources = data
        setResources(data)

        taskService.getTasksActive(0, new Date(2021, 12, 1), new Date(2022, 2, 1))
          .then((data) => {
            var taskList = []
            var parentTaskList = []
            data.forEach(element => {
              taskList.push({ id: element.id, text: (element.parentId ? '----> ' : '') + element.title + (element.parentId ? '' : ' (' + element.id + ')') })
              if (!element.parentId) parentTaskList.push({ id: element.id, text: element.title + ' (' + element.id + ')' })
              tempApps.push({ id: element.id, text: element.title + ' - Planed duration', startDate: new Date(new Date(element.start).toDateString()), endDate: new Date(new Date(element.end).toDateString()), color: '#5555ff', resourceId: 0, taskAssignmentId: 0 })
            });

            setTasks(taskList)
            setParentTasks(parentTaskList)

            taskAssignmentService.getByDate()
              .then((data) => {
                data.forEach(app => {
                  var resource = tempResources.find(element => element.id === app.userId)
                  var assignment = { id: app.taskId, text: resource.text, startDate: new Date(Date.parse(app.start)), endDate: new Date(Date.parse(app.end)), color: resource.value, resourceId: app.userId, taskAssignmentId: app.id }
                  tempApps.push({ ...assignment })
                  addUndo({ eventType: 'add', type: 'assignment', id: assignment.taskAssignmentId, resourceId: assignment.resourceId, startDate: app.start, endDate: app.end, taskId: app.taskId });
                });
                setAppointments(tempApps)
              })
          })
      })


  }, [])


  function onAppointmentRemove(e) {
    const index = resources.indexOf(e.itemData);

    if (index >= 0) {
      appointments.splice(index, 1);
      setAppointments([...appointments])
    }
  }

  function validateAssignmentDate(e) {
    var conflictingApps = null;
    conflictingApps = appointments.filter(element => element.resourceId === e.resourceId &&
      (
        (element.startDate <= e.startDate && element.endDate >= e.startDate) ||
        (element.startDate >= e.startDate && element.endDate <= e.endDate) ||
        ((element.startDate >= e.startDate && element.startDate >= e.endDate) &&
          (element.endDate >= e.startDate && (element.endDate <= e.endDate))) ||
        ((element.startDate >= e.startDate && element.startDate <= e.endDate) &&
          (element.endDate >= e.startDate && element.endDate >= e.endDate)) ||
        ((element.startDate >= e.startDate && element.endDate <= e.startDate) && element.endDate >= e.endDate)
      )
      && element.taskAssignmentId != e.taskAssignmentId)

    if (conflictingApps.length !== 0) {
      toast('Resource: "' + e.text + '" has already been assigned for this date and cannot be assigned here!', 'error')
      return false
    }
    var parentApps = appointments.filter(element => element.id === e.id && element.startDate <= e.startDate && element.endDate >= e.endDate)
    if (parentApps.length === 0) {
      toast('The targeted task dos not has activity on the selected date. You must first adjust activity to cover selected date', 'error')
      return false
    }

    return true
  }

  function onAppointmentAdd(e) {
    debugger;
    const isValidAppointments = isValidAppointment(e.component, e.itemData);
    if (!isValidAppointments) {
      e.cancel = true;
      notifyDisableDate();
      return;
    }
    const index = resources.indexOf(e.fromData);
    if (index >= 0) {
      var newApp = e.itemData
      if (!validateAssignmentDate({ id: newApp.id, resourceId: e.fromData.id, startDate: newApp.startDate, endDate: newApp.endDate, text: e.fromData.text })) return
      newApp.color = e.fromData.value
      newApp.resourceId = e.fromData.id
      var taskAssignment = { id: newApp.taskAssignmentId ? newApp.taskAssignmentId : 0, userId: newApp.resourceId, taskId: newApp.id, start: newApp.startDate, end: newApp.endDate }
      taskAssignmentService.createUpdate(taskAssignment)
        .then((app) => {
          var apps = [...appointments]
          var assignment = { id: app.taskId, text: newApp.text, startDate: new Date(Date.parse(app.start)), endDate: new Date(Date.parse(app.end)), color: newApp.value, resourceId: app.userId, taskAssignmentId: app.id }
          apps.push(assignment)
          setAppointments(apps)
          addUndo({ eventType: 'add', type: 'assignment', id: assignment.taskAssignmentId, resourceId: assignment.resourceId, startDate: e.itemData.startDate, endDate: e.itemData.endDate, taskId: e.itemData.id });
          addUndo({ eventType: 'alter', type: 'assignment', id: assignment.taskAssignmentId, resourceId: assignment.resourceId, startDate: e.itemData.startDate, endDate: e.itemData.endDate, taskId: e.itemData.id });
        })
    }
  }

  function isValidAppointment(component, appointmentData) {
    const startDate = new Date(appointmentData.startDate);
    const endDate = new Date(appointmentData.endDate);
    const cellDuration = component.option('cellDuration');
    return isValidAppointmentInterval(startDate, endDate, cellDuration);
  }

  function isHoliday(date) {
    const localeDate = date.toLocaleDateString();
    return holidays.filter((holiday) => holiday.toLocaleDateString() === localeDate).length > 0;
  }
  function isWeekend(date) {
    if(date)
    {const day = date.getDay();
    return day === 0 || day === 6;
    }
    else
    return false;
  }

  function isDinner(date) {
    const hours = date.getHours();
    return hours >= dinnerTime.from && hours < dinnerTime.to;
  }

  function isValidAppointmentDate(date) {
    return !isHoliday(date) && !isDinner(date) && !isWeekend(date);
  }

  function isValidAppointmentInterval(startDate, endDate, cellDuration) {
    const edgeEndDate = new Date(endDate.getTime() - 1);

    if (!isValidAppointmentDate(edgeEndDate)) {
      return false;
    }

    const durationInMs = cellDuration * 60 * 1000;
    const date = startDate;
    while (date <= endDate) {
      if (!isValidAppointmentDate(date)) {
        return false;
      }
      const newDateTime = date.getTime() + durationInMs - 1;
      date.setTime(newDateTime);
    }

    return true;
  }

  function onAppointmentFormOpening(e) {
    e.cancel = true;
    const startDate = new Date(e.appointmentData.startDate);
    if (!isValidAppointmentDate(startDate)) {
      e.cancel = true;
      notifyDisableDate();
    }
    applyDisableDatesToDateEditors(e.form);
  }

  function applyDisableDatesToDateEditors(form) {
    const startDateEditor = form.getEditor('startDate');
    startDateEditor.option('disabledDates', holidays);

    const endDateEditor = form.getEditor('endDate');
    endDateEditor.option('disabledDates', holidays);
  }

  function onAppointmentUpdating(e) {
    debugger;
    const isAppointmentValid = isValidAppointment(e.component, e.newData);
    if (!isAppointmentValid) {
      e.cancel = true;
      notifyDisableDate();
    }

    if (e.oldData.resourceId == 0 && e.oldData.taskAssignmentId == 0)
      toast("Assignments might be assigned to this task, you can undo if not required!", "warning")

    if (e.newData.taskAssignmentId !== 0) {
      var assignment = e.newData
      var taskAssignment = { id: assignment.taskAssignmentId, userId: assignment.resourceId, taskId: assignment.id, start: assignment.startDate, end: assignment.endDate }
      if (!validateAssignmentDate({ id: assignment.id, resourceId: assignment.resourceId, startDate: assignment.startDate, endDate: assignment.endDate, taskAssignmentId: e.newData.taskAssignmentId, text: e.newData.text })) {
        e.cancel = true
        return
      }
      taskAssignmentService.createUpdate(taskAssignment)
      addUndo({ eventType: assignment.taskAssignmentId === 0 ? 'add' : 'alter', type: 'assignment', id: assignment.taskAssignmentId, resourceId: assignment.resourceId, startDate: e.newData.startDate, endDate: e.newData.endDate, taskId: e.newData.id })
    }
    else {
      if (e.newData.id !== e.oldData.id) {
        toast("Cannot move task to another task!", "warning")
        e.cancel = true
        return
      }
      var app = e.newData
      var start = new Date(app.startDate.getTime() + app.startDate.getTimezoneOffset() * 60000 * -1);
      var days = (app.endDate.getTime() - app.startDate.getTime()) / (1000 * 3600 * 24)
      var updateApp = { id: app.id, text: app.text, PlanedStart: start, PlanedDurationDays: days }
      taskService.updateTask(updateApp)
      addUndo({ eventType: 'alter', type: 'task', id: 0, resourceId: 0, startDate: e.newData.startDate, endDate: e.newData.endDate, taskId: e.newData.id })
    }
  }

  function onListDragStart(e) {
    e.cancel = true;
  }

  function onItemDragStart(e) {
    e.itemData = e.fromData;
  }

  function onItemDragEnd(e) {
    if (e.toData) {
      e.cancel = true;
    }
  }

  function ResourceDragables() {
    var comp = [];
    resources.forEach(resource => {
      comp.push(
        (<Draggable
          key={resource.text}
          className="resource-box"
          clone={true}
          style={{ backgroundColor: resource.value }}
          group={draggingGroupName}
          data={resource}
          onDragStart={onItemDragStart}
          onDragEnd={onItemDragEnd}>
          {resource.text}
        </Draggable>)
      )
    });

    return comp
  }

  function appointmentDragStart(e) {
  }

  function onAppointmentContextMenu({ appointmentData, targetedAppointmentData }) {
    const scheduler = schedulerRef.current.instance;
    setTarget(appointmentClassName)
    setDisabled(false)
    setContextMenuItems(
      [{
        text: 'Open',
        onItemClick: function () { alert("test") }
      },
      ])
  }

  function onContextMenuItemClick(e) {
  }

  function AppointmentTooltipRender(e) {
    if (!e.appointmentData) return null

    var text = e.appointmentData.text + (!e.appointmentData.resourceId ? ' (' + e.appointmentData.id + ')' : '')

    if (!e.appointmentData.resourceId) {
      return <div className='appointment-tooltip-render'>{text}</div>
    }
    return (
      <div>
        <div>{text}</div>
        <div class="dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-icon dx-tooltip-appointment-item-delete-button dx-button-content"><i class="dx-icon dx-icon-trash"
          onClick={() => deleteAppointment(e)} /></div>
      </div>
    )
  }

  function redo(e) {
    if (redoList && redoList.list.length > 0) {
      var newRedoList = { ...redoList }
      newRedoList.position++
      var redoItem = redoList.list[newRedoList.position - 1]

      if (redoItem) {
        if (redoItem.type === 'assignment') {
          var taskAssignment = { id: redoItem.id, userId: redoItem.resourceId, taskId: redoItem.taskId, start: redoItem.startDate, end: redoItem.endDate }
          if (redoItem.eventType === 'add') taskAssignment.DeletedAt = new Date();
          if (redoItem.eventType === 'deleted') taskAssignment.DeletedAt = new Date();
          taskAssignmentService.createUpdate(taskAssignment)
          var apps = [...appointments]
          var app = apps.find(element => element.taskAssignmentId === redoItem.id)
          app.id = redoItem.taskId
          app.startDate = redoItem.startDate
          app.endDate = redoItem.endDate

          setAppointments(apps)
          setRedoList(newRedoList)
          setUndoList(newRedoList)

          if (redoItem.eventType == "add") {
            var appointment = appointments.filter(x => x.resourceId == redoItem.resourceId && x.taskAssignmentId == redoItem.id)[0];
            const index = appointments.indexOf(appointment);
            if (index >= 0) {
              appointments.splice(index, 1);
              setAppointments([...appointments])
            }

            var redoListRemaining = { ...redoList }
            redoListRemaining.list = redoList.list.filter(x => x.id != redoItem.id && x.resourceId != redoItem.resourceId && x.redoItem != redoItem.taskId);

            redoListRemaining.position = redoListRemaining.list.length;
            setRedoList(redoListRemaining);
            setUndoList(redoListRemaining)
          }
        }
        if (redoItem.type === 'task') {
          appointments.filter(x => x.resourceId == 0 && x.taskAssignmentId == 0 && x.id == redoItem.taskId)[0].startDate = redoItem.startDate;
          appointments.filter(x => x.resourceId == 0 && x.taskAssignmentId == 0 && x.id == redoItem.taskId)[0].endDate = redoItem.endDate;
          setAppointments([...appointments])

          var redoListRemaining = { ...redoList }
          redoListRemaining.list = redoList.list.filter(x => !(x.id == 0 && x.resourceId == 0 && (x.startDate === redoItem.startDate && x.endDate === redoItem.endDate)));

          redoListRemaining.position = redoListRemaining.list.length;
          setRedoList(redoListRemaining);
          setUndoList(redoListRemaining)
        }
      }
    }
  }


  function undo(e) {
    if (undoList && undoList.list.length > 0) {
      var newUnduList = { ...undoList }
      newUnduList.position--
      var undoItem = undoList.list[newUnduList.position - 1]

      if (undoItem) {
        if (undoItem.type === 'assignment') {
          var taskAssignment = { id: undoItem.id, userId: undoItem.resourceId, taskId: undoItem.taskId, start: undoItem.startDate, end: undoItem.endDate }
          if (undoItem.eventType === 'add') taskAssignment.DeletedAt = new Date();
          if (undoItem.eventType === 'deleted') taskAssignment.DeletedAt = new Date();
          taskAssignmentService.createUpdate(taskAssignment)
          var apps = [...appointments]
          var app = apps.find(element => element.taskAssignmentId === undoItem.id)
          app.id = undoItem.taskId
          app.startDate = undoItem.startDate
          app.endDate = undoItem.endDate

          setAppointments(apps)
          setUndoList(newUnduList)
          setRedoList(newUnduList)

          if (undoItem.eventType == "add") {
            var appointment = appointments.filter(x => x.resourceId == undoItem.resourceId && x.taskAssignmentId == undoItem.id)[0];
            const index = appointments.indexOf(appointment);
            if (index >= 0) {
              appointments.splice(index, 1);
              setAppointments([...appointments])
            }

            var undoListRemaining = { ...undoList }
            undoListRemaining.list = undoList.list.filter(x => x.id != undoItem.id && x.resourceId != undoItem.resourceId && x.undoItem != undoItem.taskId);

            undoListRemaining.position = undoListRemaining.list.length;
            setUndoList(undoListRemaining);
            setRedoList(undoListRemaining)
          }
        }
        if (undoItem.type === 'task') {
          appointments.filter(x => x.resourceId == 0 && x.taskAssignmentId == 0 && x.id == undoItem.taskId)[0].startDate = undoItem.startDate;
          appointments.filter(x => x.resourceId == 0 && x.taskAssignmentId == 0 && x.id == undoItem.taskId)[0].endDate = undoItem.endDate;
          setAppointments([...appointments])

          var undoListRemaining = { ...undoList }
          undoListRemaining.list = undoList.list.filter(x => !(x.id == 0 && x.resourceId == 0 && (x.startDate === undoItem.startDate && x.endDate === undoItem.endDate)));

          undoListRemaining.position = undoListRemaining.list.length;
          setUndoList(undoListRemaining);
          setRedoList(undoListRemaining);
        }
      }
    }
  }

  function addUndo(e) {
    var newState = { ...undoList }
    newState.list.push(e);
    if (e.eventType == "add") {
      undoList.position++;
    }
    else
      newState.position++;
    setUndoList(newState);
  }

  function deleteAppointment(e) {
    var appointment = appointments.filter(x => x.resourceId == e.appointmentData.resourceId && x.taskAssignmentId == e.appointmentData.taskAssignmentId)[0];
    const index = appointments.indexOf(appointment);

    if (e.appointmentData.taskAssignmentId !== 0) {
      var taskAssignment = { id: e.appointmentData.taskAssignmentId, userId: e.appointmentData.resourceId, taskId: e.appointmentData.id, start: e.appointmentData.startDate, end: e.appointmentData.endDate }
      taskAssignment.DeletedAt = new Date();
      taskAssignmentService.createUpdate(taskAssignment)
        .then((app) => {
          if (index >= 0) {
            appointments.splice(index, 1);
            setAppointments([...appointments])

            if (undoList != null && undoList.list.length > 0) {
              var undoListRemaining = { ...undoList }
              undoListRemaining.list = undoList.list.filter(x => x.id != e.appointmentData.id && x.resourceId != e.appointmentData.resourceId
                && x.taskAssignmentId != e.appointmentData.taskAssignmentId);

              undoListRemaining.position = undoListRemaining.list.length;
              setUndoList(undoListRemaining);
            }

            if (redoList != null && redoList.list.length > 0) {
              var redoListRemaining = { ...redoList }
              redoListRemaining.list = redoList.list.filter(x => x.id != e.appointmentData.id && x.resourceId != e.appointmentData.resourceId
                && x.taskAssignmentId != e.appointmentData.taskAssignmentId);

              redoListRemaining.position = redoListRemaining.list.length;
              setRedoList(redoListRemaining);
            }
          }
        })
    }
  };


  function RenderTimeCell(e) {
    return
    <i style='color: green'>{e.text}</i>;
  }

  function renderDataCell(data, index) {
    if (data && data.date) {
      var weekendData = <div style={{ width: '100%', height: 40 }}></div>;
      var iswkdend = isWeekend(data.date);
      if (iswkdend) {
        weekendData = <div className={iswkdend ? 'disable-date' : null} style={{ width: '100%', height: 40, backgroundColor: 'red' }}></div>
      }
      return weekendData;
    } else
      return weekendData;
  }

  function renderDateCell(data, index) {
    if (data && data.date) {
      var iswkdend = isWeekend(data.date);
      if (isWeekend(data.date)) {
        return <b className={iswkdend ? 'disable-date' : null} style={{ color: 'red', fontWeight: 'lighter' }}><p>{data.text}</p></b>;
      } else
        return <b><p>{data.text}</p></b>;
    } else
    return <b><p>{data.text}</p></b>;
  }
  function renderTimeCell(data, index) {
    if (data && data.date) {
      var iswkdend = isWeekend(data.date);
      if (iswkdend) {
        return <b className={iswkdend ? 'disable-date' : null} style={{ color: 'pink', fontWeight: 'bold' }}><p>{data.text}</p></b>;
      }
      else
      return <b><p>{data.text}</p></b>;
    }
    else
    return <b><p>{data.text}</p></b>;
  }



  return (
    <React.Fragment>

      <div style={{ whiteSpace: 'nowrap', display: 'flex' }}>
        <ScrollView id="scroll" style={{ display: 'inline-block' }}>
          <Draggable
            id="list"
            data="dropArea"
            group={draggingGroupName}
            onDragStart={onListDragStart}>
            <ResourceDragables />
          </Draggable>
        </ScrollView>
        <Button
          style={{ position: 'inherit', height: '40px', width: '40px', borderRadius: '3px', marginLeft: '5px' }}
          hint='Undo'
          icon='undo'
          onClick={undo}
        />
        <Button
          style={{ position: 'inherit', height: '40px', width: '40px', borderRadius: '3px', marginLeft: '5px' }}
          hint='Redo'
          icon='redo'
          onClick={redo}
        />
      </div>

      <Scheduler
        ref={schedulerRef}
        dataSource={appointments}
        height={700}
        defaultCurrentView="Timeline 1 month"
        defaultCurrentDate={currentDate}
        startDayHour={startDayHour}
        endDayHour={endDayHour}
        cellDuration={480}
        showAllDayPanel={true}
        groups={groups}
        appointmentComponent={Appointment}
        // dataCellRender={renderDataCell}
        dateCellRender={renderDateCell}
        timeCellRender={renderTimeCell}


        // dataCellRender={renderDataCell}
        onAppointmentContextMenu={onAppointmentContextMenu}
        appointmentTooltipRender={AppointmentTooltipRender}
        onAppointmentUpdating={onAppointmentUpdating}
        onAppointmentFormOpening={onAppointmentFormOpening}
        editing={editOptions}
      >

        <View
          type="timelineMonth"
          name="Timeline 1 month"
          groupOrientation="vertical"
          maxAppointmentsPerCell={4}
          intervalCount={1}
        />
        <View
          type="timelineMonth"
          name="Timeline 2 month"
          groupOrientation="vertical"
          maxAppointmentsPerCell={4}
          intervalCount={2}
        />
        <View
          type="timelineMonth"
          name="Timeline 3 month"
          groupOrientation="vertical"
          maxAppointmentsPerCell={3}
          intervalCount={3}
        />
        <View
          type="workWeek"
          groups={priorityGroups}
          startDayHour={9}
          endDayHour={18}
        />


        <Resource fieldExpr="id" dataSource={tasks} label="Task" allowMultiple={false} />
        <Scrolling mode="virtual" />
        <AppointmentDragging
          group={draggingGroupName}
          onRemove={onAppointmentRemove}
          onAdd={onAppointmentAdd}
        />
        <ContextMenu
          dataSource={contextMenuItems}
          width={200}
          target={target}
          disabled={disabled}
          onItemClick={onContextMenuItemClick}
          itemRender={AppointmentMenuTemplate}
        />
      </Scheduler>
    </React.Fragment>
  );
}

export default App;
