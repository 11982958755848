import React, { useCallback, useEffect, useState, useContext, useRef, createRef } from 'react';

//import 'devextreme/data/odata/store';
import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Lookup,
    Editing,
    Scrolling,
    FilterPanel,
    Sorting,
    HeaderFilter,
    SearchPanel,
    Grouping,
    GroupPanel,
    ColumnChooser,
    Export,
    StateStoring,
    RequiredRule,
    MasterDetail,
    RowDragging,
} from 'devextreme-react/data-grid';
import TaskDetail from './taskDetails'
import './grid.scss'
import TasksSub from './tasksSub'
import { taskService } from '../../services/task-service'
//import CustomStore from 'devextreme/data/custom_store';
import WorkPopUp from '../workItems/work-popup';
import { confirm } from 'devextreme/ui/dialog';
import * as TaskEnum from '../../constant/taskEnum';
import * as RoleEnum from '../../constant/roleEnum';
import * as Constants from '../../constant/appConst';
import WorkTimesheet from '../workItems/workTimesheet';
import { chainService } from '../../services/chain-service'
import { SelectBox, LoadPanel } from 'devextreme-react';
import { localStorageService } from "../../services/localstorage-service";
import TaskComment from '../taskComment/TaskComment';
import { SplitView } from "../../utils/split-view";
import { toast } from '../../common/common'
import { taskDataSource } from '../../data/taskDatasource'

const Task = (props) => {

    const gridRef = createRef();

    const [isPopup, setIsPopup] = useState(false);
    const [taskData, setTaskData] = useState({});
    const [isPopupWorkTimesheet, setIsPopupWorkTimesheet] = useState(false);
    const [assignedToUsers, setAssignedToUser] = useState([]);
    const [chains, setChains] = useState([]);
    const [selectedChain, setSelectedChain] = useState(-1);
    const [isLoading, setIsLoading] = useState(false);
    const percentMaxValue = { max: '100' }
    let loaderPosition = { of: "#task-grid" };
    const [dataSource, setDataSource] = useState({});
    const [isWorkTimesheet, setIsWorkTimesheet] = useState(false);
    const [itemList, setItemList] = useState({});
    const [isNormalView, setIsNormalView] = useState(true);
    const [buttonTextName, setButtonTextName] = useState('Split View');
    const [isTaskCommentHistory, setIsTaskCommentHistory] = useState(false);

    const storageKey = "task-gridList";

    // The current position of mouse
    let x = 0;
    let y = 0;

    // Width of left side
    let leftWidth = 0;
    let resizer = null;
    const openWorkItem = (e, data) => {
        if (isNotEmpty(data))
            setTaskData(data);
        else
            setTaskData(null);

        setIsPopup(true);
    }

    const openWorkTimesheet = (e, data) => {
        if (isNotEmpty(data)) {
            setTaskData(data);
            setIsLoading(false);
            setIsPopupWorkTimesheet(true);
        }
    }

    const timesheetData = (data) => {
        setTaskData(data);
        setItemList({
            task: data
        });
    }

    const popUpItem = {
        workItemPopUp: isPopup,
        task: taskData,
        workTimesheetPopUp: isPopupWorkTimesheet
    }

    const hideInfo = () => {
        setIsPopup(false);
    }

    const hideWorkTimesheetPopUp = () => {
        setIsPopupWorkTimesheet(false);
    }

    const event = {
        hidePopUpInfo: hideInfo,
        hideWorkTimesheetPopUpInfo: hideWorkTimesheetPopUp
    }

    const loadState = useCallback(() => {
        return localStorageService.getStateTaskList(storageKey);
    }, []);

    const saveState = useCallback((state) => {
        if (state) {
            for (let i = 0; i < state.columns.length; i++) {
                state.columns[i].filterValue = null;
            }
        }

        localStorageService.setStateTaskList(storageKey, state)
    }, []);


    function isNotEmpty(value) {
        return value !== undefined && value !== null && value !== '';
    }

    useEffect(() => {
        if (!isPopupWorkTimesheet && (!isWorkTimesheet || !isTaskCommentHistory)) {
            setIsLoading(true);
            getVSTSUsers();
            setDataSource(taskDataSource(selectedChain));
            setIsLoading(false);
        }
        SplitView.dragHandleSplitView();
    }, [isPopupWorkTimesheet, isWorkTimesheet, isTaskCommentHistory]);

    const getVSTSUsers = async () => {
        let chain = await chainService.getChain();
        setChains(chain);
        let assignedToUsers = await taskService.getUsers();
        setAssignedToUser(assignedToUsers);
    }


    // const taskDataSource = (chainID) => {
    //     return {
    //         store: new CustomStore({
    //             key: 'id',
    //             load: async (loadOptions) => {
    //                 let params = '?';
    //                 [
    //                     'skip',
    //                     'take',
    //                     'requireTotalCount',
    //                     'requireGroupCount',
    //                     'sort',
    //                     'filter',
    //                     'totalSummary',
    //                     'group',
    //                     'groupSummary'
    //                 ].forEach(function (i) {
    //                     if (i in loadOptions && isNotEmpty(loadOptions[i])) {
    //                         params += `${i}=${JSON.stringify(loadOptions[i])}&`;
    //                     }
    //                 });
    //                 params = params.slice(0, -1);
    //                 let taskData = await taskService.getTasks(params, chainID, 0);
    //                 return {
    //                     data: taskData.data,
    //                     totalCount: taskData.totalCount,
    //                     summary: taskData.summary,
    //                     groupCount: taskData.groupCount
    //                 };
    //             },
    //             insert: async (values) => {
    //                 if (values != null) {
    //                     if (chainID != -1) {
    //                         values.chainId = chainID;
    //                     }
    //                     let taskData = await taskService.addTask(values);
    //                     toast("Task is created ", "success");
    //                 }

    //             },

    //             update: async (key, values) => {
    //                 debugger
    //                 values.id = key;
    //                 if (key > 0) {
    //                     if (chainID != -1) {
    //                         values.chainId = chainID;
    //                     }
    //                     await taskService.updateTask(values);
    //                     toast("Task is updated ", "success");
    //                 }
    //             },
    //             remove: async (key) => {
    //                 if (key > 0) {
    //                     await taskService.deleteTask(key);
    //                     toast("Task is deleted ", "success");
    //                 }
    //             },
    //         })
    //     }
    // }



    function onReorder(e) {
        e.promise = processReorder(e);
    }

    async function processReorder(e) {
        var visibleRows = gridRef.current.instance.getVisibleRows()
        var toRow = visibleRows[e.toIndex]
        if (toRow) {
            if (toRow.data.priority && selectedChain) {
                var values = {
                    id: e.itemData.id,
                    // newPriority: toRow.data.priority,
                    newPriority: e.toIndex + 1,
                    chainId: selectedChain === -1 ? null : selectedChain
                };

                await taskService.updateTask(values);
                await e.component.refresh();
            }
            else {
                toast("Can't drag a task to a row that has no priority", "warning");
            }
        }
    }

    function onToolbarPreparing(e) {

        let isChainVisible = visibleColumn();

        if (isChainVisible) {
            e.toolbarOptions.items.unshift({
                location: 'before',
                widget: 'dxSelectBox',
                options: {
                    dataSource: chains,
                    displayExpr: 'text',
                    valueExpr: 'id',
                    value: selectedChain,
                    showClearButton: true,
                    onValueChanged: onChainValueChanged,
                    placeholder: 'Select chain...'
                }
            });
        }

        e.toolbarOptions.items.unshift({
            location: 'before',
            widget: 'dxButton',
            options: {
                icon: 'filter',
                text: 'Filter view',
                onClick: buttonNormalViewOnClick
            }
        });

        e.toolbarOptions.items.unshift({
            location: 'before',
            widget: 'dxButton',
            options: {
                icon: isNormalView ? 'fields' : 'fullscreen',
                text: buttonTextName,
                onClick: buttonSplitView
            }
        });
    }

    function buttonSplitView() {
        if (isNormalView) {
            setButtonTextName('Normal View');
            setIsNormalView(false);
        } else {
            setButtonTextName('Split View');
            setIsNormalView(true);
        }
    }

    function buttonNormalViewOnClick() {
        //gridRef.current.instance.filter(["importance", "=", 1], "and", ["priority", ">", 0], "and", ["solvedAt", "=", null]); // [Solved] Is any of('(Blanks)')
        //gridRef.current.instance.filterValue(["solvedAt", "anyof", []]);
        //gridRef.current.instance.filterValue(["importance", "=", 1]);
        // var solvedFilterDate = new Date(Date.now() - (14*24*3600*1000))
        const filterVal = [["importance", "=", 1], "and", ["priority", ">", 0], "and", ["solvedAt", "=", null]];
        // const filterVal = [["importance","=",1],"and",["priority",">",0],"and",[["solvedAt","=",null],"or",["solvedAt",">","12/22/2022"]]];
        gridRef.current.instance.option("filterValue", filterVal)
    }

    //const filter1 = ["solvedAt", "=", null]; 

    const renderVSOLink = (data) => {
        if (data.data.vsoId) {
            return (<div className="task-grid-button">
                <a href={Constants.apiConst.VSO_LINK + "TouchSoft/_workitems/edit/" + data.data.vsoId} target='_blank' className="p6 cursor-pointer link" title="Link" aria-label="Link">{data.data.vsoId}</a>
                <a onClick={(e) => rowCopyVSO(e, data.data.vsoId)} className="mr-5 cursor-pointer link dx-icon-copy dx-link-icon" title="Copy" aria-label="Copy"></a>
            </div>);
        } else if (data.data.id > 0) {
            return (<div className="task-grid-button">
                <a onClick={() => rowAddVSO(data.data)} className="mr-5 cursor-pointer link dx-icon-add dx-link-icon" title="Include in VSO" aria-label="Include in VSO"></a>
            </div>);
        } else {
            return '';
        }
    }

    const rowAddVSO = async (data) => {
        try {
            var task = await taskService.addVSO(data);
            if (task != null) {
                toast("Task is added in VSO", "success");
                setDataSource(taskDataSource(selectedChain));
            }
        } catch (e) {

        }
    }

    const rowCopyVSO = (e, vsoId) => {
        let link = Constants.apiConst.VSO_LINK + "TouchSoft/_workitems/edit/" + vsoId;
        navigator.clipboard.writeText(link);
        toast("Link copied ", "warning");
    }

    const rowDelete = async (data) => {
        var result = confirm("Are you sure you want to delete this record ?", data.area);
        result.done(async (dialogResult) => {
            if (dialogResult) {
                await taskService.deleteTask(data.id);
                toast("Task is deleted ", "success");
                setDataSource(taskDataSource(selectedChain));
            }
        });
    }

    const renderGridCell = (data) => {
        if (data.data.id) {
            return (<div className="task-grid-button">
                <a onClick={(e) => openWorkTimesheet(e, data.data)} className="p6 cursor-pointer" title="Work-Timesheet" aria-label="Work-Timesheet">View</a>
            </div>);
        }
    }

    const onChainValueChanged = (e) => {
        if (e.event != null) {
            let value = e.value == null ? -1 : e.value;
            setSelectedChain(value);
            setDataSource(taskDataSource(value));
        }
    }

    const taskOnSelectionChanged = (e) => {
        if (!isNormalView) {
            setIsWorkTimesheet(false);
            setIsTaskCommentHistory(false);
            const data = e.selectedRowsData[0];
            if (data != null) {

                setTimeout(function () {
                    timesheetData(data);
                    setIsWorkTimesheet(true);
                    setIsTaskCommentHistory(true);
                }, 500)
            }
        }
    }

    const renderTimesheet = () => {
        return (<div className="container__left">

            {
                isWorkTimesheet
                    ?
                    <WorkTimesheet properties={itemList} event={event} ></WorkTimesheet>
                    :
                    ''
            }

        </div>);
    }

    const renderBottomView = () => {
        var showTimesheet = visibleColumn();
        if (!showTimesheet) {
            return (<div className="container__bottom">
                <div className="container__right">
                    {
                        isTaskCommentHistory
                            ?
                            <TaskComment properties={itemList} ></TaskComment>
                            :
                            ''
                    }
                </div>
            </div >);
        } else {
            return (<div className="container__bottom">
                {renderTimesheet()}
                <div className='resizer' data-direction='horizontal'></div>
                <div className="container__right">
                    {
                        isTaskCommentHistory
                            ?
                            <TaskComment properties={itemList} ></TaskComment>
                            :
                            ''
                    }
                </div>
            </div >);
        }
    }

    const visibleColumn = () => {
        var isCustomer = RoleEnum.userRoles.find(x => x.id === parseInt(localStorageService.getUserRole()));
        if (isCustomer.id === RoleEnum.userRoles[2].id || isCustomer.id === RoleEnum.userRoles[3].id) {
            return false;
        }
        return true;
    }

    const taskGrid = () => {

        return (<div id="task-grid" style={{ height: isNormalView ? 'calc(100vh - 80px)' : '98% !important', width: isNormalView ? '100%' : '95.5% !important' }}>

            <DataGrid
                ref={gridRef}
                className={'dx-card wide-card'}
                dataSource={dataSource}
                keyExpr={'id'}
                showBorders={true}
                focusedRowEnabled={true}
                defaultFocusedRowIndex={0}
                // columnAutoWidth={true}
                columnHidingEnabled={true}
                remoteOperations={true}
                //rowAlternationEnabled={true}
                columnAutoWidth={true}
                allowColumnResizing={true}
                columnResizingMode='widget'
                allowColumnReordering={true}
                width={isNormalView ? '100%' : '99.5%'}
                height='100%'
                elementAttr={{ class: 'full-screen-height' }}
                showColumnLines={true}
                onToolbarPreparing={onToolbarPreparing}
                columnWidth={50}
                selection={{ mode: 'single' }}
                onSelectionChanged={taskOnSelectionChanged}
            >
                <LoadPanel
                    position={loaderPosition}
                    visible={isLoading}
                    shading={true}
                />


                <Editing
                    mode="row"
                    allowAdding={true}
                    allowUpdating={parseInt(localStorageService.getUserRole()) != RoleEnum.userRoles[3].id}
                    allowDeleting={parseInt(localStorageService.getUserRole()) != RoleEnum.userRoles[3].id}
                />

                <FilterPanel visible={true} />
                {/*<Sorting mode="multiple" />*/}
                <Scrolling mode="infinite" showScrollbar={'always'} columnRenderingMode="virtual" />

                <Paging pageSize={30} />
                {/*<HeaderFilter visible={true} allowSearch={true} />*/}
                <SearchPanel visible={true}
                    width={240}
                    placeholder="Search..." />

                {/* <Grouping autoExpandAll={true} />
<GroupPanel visible={true} />      */}
                <ColumnChooser enabled={true} />
                <FilterRow visible={true} />
                <Export enabled={true} allowExportSelectedData={true} />
                <StateStoring enabled={true} customLoad={loadState}
                    storageKey={storageKey}
                    customSave={saveState} type="custom" />

                <MasterDetail
                    enabled={true}
                    component={TasksSub}
                />

                <RowDragging
                    allowReordering={true}
                    onReorder={onReorder}
                    showDragIcons={true}
                />

                <Column
                    dataField={'id'}
                    caption={'Id'}
                    allowEditing={false}
                    width={70}

                />

                <Column
                    dataField={'area'}
                    caption={'Area'}
                    width={150}
                />

                <Column
                    dataField={'initiator'}
                    caption={'Initiator'}
                    width={80}
                />

                <Column
                    dataField={'taskType'}
                    caption={'Type'}
                    width={80}
                >
                    <Lookup
                        dataSource={TaskEnum.taskTypes}
                        valueExpr={'value'}
                        displayExpr={'text'}
                    />
                    <RequiredRule
                        message={'Please select a type'}
                    />
                </Column>

                <Column
                    dataField={'status'}
                    caption={'Status'}
                    visible={false}
                    width={120}
                >
                    <Lookup
                        dataSource={TaskEnum.statusTypes}
                        valueExpr={'value'}
                        displayExpr={'text'}
                    />
                </Column>

                <Column
                    dataField={'assignedToEmail'}
                    caption={'Assigned User'}
                    width={100}
                >
                    <Lookup
                        dataSource={assignedToUsers}
                        valueExpr={'value'}
                        displayExpr={'text'}
                    />
                </Column>
                <Column
                    dataField={'importance'}
                    caption={'Importance'}
                    dataType='number'
                    width={60}
                />

                <Column
                    dataField={'priority'}
                    caption={'Priority'}
                    dataType='number'
                    width={60}
                //allowEditing={false}
                />

                <Column
                    dataField={'mark'}
                    caption={'Mark'}
                    dataType='boolean'
                    visible={false}
                    width={50}
                />

                <Column
                    dataField={'title'}
                    caption={'Title'}
                    width={280}
                >
                    <RequiredRule
                        message={'Title must be entered'}
                    />
                </Column>

                <Column
                    dataField={'description'}
                    caption={'Description'}
                    width={280}
                />

                <Column
                    dataField={'parentId'}
                    caption={'Parent'}
                    visible={false}
                    width={50}
                />

                <Column
                    dataField={'hasChildren'}
                    caption={'Har deloppgaver'}
                    visible={false}
                    allowEditing={false}
                    width={50}
                />



                <Column
                    dataField={'actualStart'}
                    caption={'Actual Start'}
                    dataType={'date'}
                    format={TaskEnum.dateFormat}
                    visible={false}
                    width={50}
                />

                <Column
                    dataField={'planedStart'}
                    caption={'Planed Start'}
                    dataType={'date'}
                    format={TaskEnum.dateFormat}
                    visible={false}
                    width={50}
                />

                <Column
                    dataField={'actualDurationDays'}
                    caption={'Actual Duration Days'}
                    dataType={'number'}
                    visible={false}
                    width={50}
                />

                <Column
                    dataField={'planedDurationDays'}
                    caption={'Planed Duration Days'}
                    dataType={'number'}
                    visible={false}
                    width={50}
                />


                <Column
                    dataField={'percentFinish'}
                    caption={'Percent Finish'}
                    dataType={'number'}
                    visible={true}
                    editorOptions={percentMaxValue}
                    width={70}
                />

                <Column
                    dataField={'solvedAt'}
                    caption={'Solved'}
                    width={80}
                    dataType={'date'}
                    format={TaskEnum.dateFormat}
                />

                <Column
                    dataField={'solvedDescription'}
                    caption={'Solved-description'}
                    width={50}
                    visible={false}
                />

                <Column
                    dataField={'storeName'}
                    caption='Store Name'
                    width={90}
                    visible={false}
                />
                {

                    visibleColumn()
                        ?
                        <Column
                            cellRender={renderVSOLink}
                            caption={'VsoLink'}
                            width={95}
                            dataField={'vsoId'}
                            visible={false}
                        />
                        :
                        ''
                }

                {

                    visibleColumn()
                        ?
                        <Column
                            caption={'Work-Timesheet'}
                            cellRender={renderGridCell}
                            width={80}
                        />
                        :
                        ''
                }
                {visibleColumn() ?
                    <Column
                        dataField={'invoiced'}
                        caption={'Invoiced'}
                        dataType='date'
                        visible={false}
                        width={80}
                    />
                    : ''}
                {visibleColumn() ?
                    <Column
                        dataField={'clearForStartUp'}
                        caption={'Clear for StartUp'}
                        dataType='date'
                        visible={false}
                        width={80}
                    />
                    : ''}
                {visibleColumn() ?
                    <Column
                        dataField={'estimatedHours'}
                        caption={'Estimated Hours'}
                        dataType='number'
                        visible={false}
                        width={80}
                    />
                    : ''}
                {visibleColumn() ?
                    <Column
                        dataField={'branch'}
                        caption={'Branch'}
                        dataType='number'
                        visible={true}
                        width={80}
                    ><Lookup
                            dataSource={TaskEnum.branchTypes}
                            valueExpr={'value'}
                            displayExpr={'text'}
                        />
                    </Column>
                    : ''}

                <Column
                    dataField={'active'}
                    caption={'Show in gant'}
                    dataType='boolean'
                    visible={true}
                    width={50}
                />

            </DataGrid>
        </div >)
    }

    return (
        <React.Fragment>
            <div id="task-page" className="row">

                {
                    isPopupWorkTimesheet ?
                        <WorkPopUp properties={popUpItem} event={event} ></WorkPopUp>
                        :
                        ""
                }

                {

                    isNormalView
                        ?
                        taskGrid()
                        :
                        <div className="container_Spl">

                            <div className="container__right">

                                <div className="container__top" >
                                    {taskGrid()}

                                </div>

                                <div className="resizer" data-direction="vertical"></div>

                                {renderBottomView()}

                            </div>
                        </div>
                }





            </div>
        </React.Fragment>
    );

}

export default Task;