import React, { useState, useEffect, useContext, createRef, useRef } from 'react';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup'; 
import WorkTimesheet from './workTimesheet';

const WorkPopUp = (props) => {

    const [popupVisible, setPopupVisible] = useState(false);

    useEffect(() => {
        if (props.properties.workTimesheetPopUp) {
            setPopupVisible(true);
        }
    }, []);    


    return (
        <React.Fragment>
            <Popup
                visible={popupVisible}
                onHiding={props.event.hideWorkTimesheetPopUpInfo}
                dragEnabled={false}
                closeOnOutsideClick={true}
                showCloseButton={true}
                showTitle={true}
                title={props.properties.task.title}
                container=".dx-viewport"
                width={'85vw'}
                height={'80vh'}
            >

                <WorkTimesheet properties={props.properties} event={props.event} ></WorkTimesheet>

            </Popup>
        </React.Fragment>
    )
}

export default WorkPopUp;

