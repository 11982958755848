import * as Constants from '../constant/appConst'
import httpService from './httpService';


export const taskAssignmentService = {    
    getByDate,
    createUpdate,
}
async function getByDate(from, to) {
    // return await httpService.get(Constants.apiConst.ENDPOINT + Constants.apiConst.TASKASSIGNMENT_GET_BY_DATE + "?from=" + from + "&to=" + to, {});
    return await httpService.get(Constants.apiConst.ENDPOINT + Constants.apiConst.TASKASSIGNMENT_GET_BY_DATE, {});
}

async function createUpdate(taskAssignment) {
    if (taskAssignment.id == 0)
      return await httpService.post(Constants.apiConst.ENDPOINT + Constants.apiConst.TASKASSIGNMENT_CREATE, taskAssignment);
    else
      return await httpService.put(Constants.apiConst.ENDPOINT + Constants.apiConst.TASKASSIGNMENT_UPDATE, taskAssignment);
}