import React, { useEffect, useState, createRef } from 'react';
import 'devextreme/data/odata/store';
import Gantt, {
    Tasks, Column, Editing, Toolbar, Item, FilterRow
} from 'devextreme-react/gantt';
import './gantt.css'
import '../../../../node_modules/devexpress-gantt/dist/dx-gantt.css'
import { taskService } from '../../../services/task-service'
import { localStorageService } from "../../../services/localstorage-service";
import * as RoleEnum from '../../../constant/roleEnum';
import { toast } from '../../../common/common'
const gantRef = createRef()

function Gant() {
    const [selectedChain, setSelectedChain] = useState(-1);
    const [dataSource, setDataSource] = useState({});
    const [isCustomer, setIsCustomer] = useState(true);


    var startDateRange = new Date()
    startDateRange.setDate(startDateRange.getDate() - 14)
    var endDateRange = new Date()
    endDateRange.setDate(endDateRange.getDate() + 30)

    useEffect(() => {

        var role = RoleEnum.userRoles.find(x => x.id === parseInt(localStorageService.getUserRole()));
        var isCustomerVal = role.id === RoleEnum.userRoles[2].id || role.id === RoleEnum.userRoles[3].id
        setIsCustomer(isCustomerVal)

        var selectedChainVal = isCustomerVal ? 1 : 0
        setSelectedChain(selectedChainVal)

        taskService.getTasksActive(selectedChainVal, new Date(2021, 12, 1), new Date(2022, 2, 1))
            .then((data) => {

                if (isCustomerVal)
                    data = data.filter(x => x.parentId == null);
                data.forEach(element => {
                    element.progress = element.percentFinish ? element.percentFinish : 0
                });

                setDataSource(data)
            })

        setTimeout(() => {
            gantRef.current.instance.option('taskListWidth', 600)
        }, 500);

    }, [])

    function onTaskInserted(e) {

        var today = new Date();
        var todayDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        var start = new Date(e.values.start.getTime() + e.values.start.getTimezoneOffset() * 60000 * -1);
        var days = (e.values.end.getTime() - e.values.start.getTime()) / (1000 * 3600 * 24)
        if (days == 0) days = 1
        var newTask = { ID: 0, Title: e.values.title, PlanedStart: start, PlanedDurationDays: days, ParentId: e.values.parentId, Active: true }
        taskService.addTask(newTask)
    }

    function onTaskDeleted(e) {
        taskService.deleteFromGantt(e.key);
    }

    function onInitialized(e) {
    }

    function onContentReady(args) {
        setTimeout(function () {
            var keysToCollapse = [2, 26, 33, 49, 58];
            var allKeys = args.component.getVisibleTaskKeys();
            for (var i = 0; i < allKeys.length; i++)
                if (keysToCollapse.indexOf(allKeys[i]) >= 0)
                    args.component._treeList.collapseRow(allKeys[i])
        }, 600)

    }

    function onTaskUpdating(e) {
        debugger

        var task = {}
        task.ID = e.key

        var parent = dataSource.find(element => element.id == e.values.parentId);
        var start = e.values.start
        if (e.newValues.start) {
            start = e.newValues.start
            if (parent) {
                var parentStart = new Date(new Date(parent.start).toDateString())
                if (start < parentStart) {
                    toast("Sub-task-start can't bee before parent-task-start !", "warning")
                    e.cancel = true
                    return
                }
            }
        }
        start = new Date(start.getTime() + start.getTimezoneOffset() * 60000 * -1)
        task.PlanedStart = start

        var end = e.values.end
        if (e.newValues.end) {
            end = e.newValues.end
            if (parent) {
                var parentend = new Date(new Date(parent.end).toDateString())
                if (end > parentend) {
                    toast("Sub-task-end can't exceed parent-task-end !", "warning")
                    e.cancel = true
                    return
                }
            }
        }
        var days = (end.getTime() - start.getTime()) / (1000 * 3600 * 24)
        task.PlanedDurationDays = days

        if (e.newValues.title) task.Title = e.newValues.title
        if (e.newValues.progress) task.PercentFinish = e.newValues.progress

        taskService.updateTask(task)
    }


    return (
        <div>
            <Gantt
                ref={gantRef}
                taskListWidth={400}
                scaleType="months"
                height={'90vh'}
                width='100%'
                rootValue={-1}
                key='ID'
                firstDayOfWeek={0}
                // startDateRange = {startDateRange}
                // endDateRange = {endDateRange} >
                onTaskInserted={onTaskInserted}
                onTaskUpdating={onTaskUpdating}
                onTaskDeleted={onTaskDeleted}
                onInitialized={onInitialized}
                onContentReady={onContentReady}
            >


                <Tasks dataSource={dataSource} />
                <Toolbar>
                    <Item name="undo" />
                    <Item name="redo" />
                    <Item name="separator" />
                    <Item name="collapseAll" />
                    <Item name="expandAll" />
                    <Item name="separator" />
                    <Item name="addTask" />
                    <Item name="deleteTask" />
                    <Item name="separator" />
                    <Item name="zoomIn" />
                    <Item name="zoomOut" />
                </Toolbar>
                <Column dataField="ID" caption="ID" width={50} />
                <Column dataField="title" caption="Subject" width={200} />
                <Editing enabled={!isCustomer} />
            </Gantt>
        </div>
    )
}

export default Gant;
