import * as Constants from '../constant/appConst';
import httpService from "./httpService";


export const taskService = {
    getWorkItems,
    getTasks,
    getTasksActive,
    addTask,
    getUsers,
    deleteTask,
    deleteFromGantt,
    updateTask,
    getTaskByTaskId,
    addVSO
};

async function getWorkItems() {
    return await httpService.get(Constants.apiConst.ENDPOINT + Constants.apiConst.TASK, {});
}

async function getTasks(params, chainId, parentId) {
    return await httpService.get(Constants.apiConst.ENDPOINT + Constants.apiConst.TASK_ALL + params + "&chainId=" + chainId + "&parentId=" + parentId, {});
}

async function getTasksActive(chainId, start, end) {
    return await httpService.get(Constants.apiConst.ENDPOINT + Constants.apiConst.TASK_ACTIVE  + "?chainId=" + chainId + "&start=" + start  + "&end=" + end, {});
}

async function addTask(task) {
    return await httpService.post(Constants.apiConst.ENDPOINT + Constants.apiConst.ADD_TASK, task);
}

async function getUsers() {
    return await httpService.get(Constants.apiConst.ENDPOINT + Constants.apiConst.VSTS_USERS);
}

async function deleteTask(id) {
    let path = Constants.apiConst.ENDPOINT + Constants.apiConst.REMOVE_TASK;
    path = path.replace("{taskId}", id);
    return await httpService.delete(path, {});
}
async function deleteFromGantt(id) {
    debugger;
    let path = Constants.apiConst.ENDPOINT + Constants.apiConst.REMOVE_TASK_FROM_GANTT;
    path = path.replace("{taskIdForGantt}", id);
    return await httpService.get(path, {});
}

async function updateTask(task) {
    let path = Constants.apiConst.ENDPOINT + Constants.apiConst.UPDATE_TASK;
    return await httpService.put(path, task);
}

async function getTaskByTaskId(taskId) {
    let path = Constants.apiConst.ENDPOINT + Constants.apiConst.TASK_BY_TASKID;
    path = path.replace("{taskId}", taskId);
    return await httpService.get(path, {});
}

async function addVSO(task) {
    return await httpService.post(Constants.apiConst.ENDPOINT + Constants.apiConst.ADD_VSO, task);
}