import * as Constants from '../constant/appConst';
import { localStorageService } from "./localstorage-service";
import notify from 'devextreme/ui/notify';

export const authenticationService = {
    login,
    checkValidToken,
    configurePassword
};

function login(email, password) {   
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    };
    let url = Constants.apiConst.ENDPOINT + Constants.apiConst.LOGIN + '?email=' + email + '&password=' + password;
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(user => {            
            return user;
        });
}

function checkValidToken(userId, token) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };
    let path = Constants.apiConst.ENDPOINT + Constants.apiConst.CHECKVALIDTOKEN + "?userId=" + userId + "&token=" + token;
    return fetch(path, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user;
        });
}

function configurePassword(userId, password, token) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId, password, token })

    };
    return fetch(Constants.apiConst.ENDPOINT + Constants.apiConst.CONFIGUREPASSWORD, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                localStorageService.clearStorage();
                //window.location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            toast(error, 'warning');
            return Promise.reject(error);
        }

        return data;
    });
}


function toast(message, infoType) {
    notify({
        message: message,
        position: {
            my: 'center top',
            at: 'center top'
        }
    }, infoType, 3000);
}