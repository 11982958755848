export const localStorageService = {
    getAuthToken,
    getEmail,
    setAuthToken,
    setEmail,
    clearStorage,
    setLoginTime,
    getLoginTime,    
    setUserRole,
    getUserRole,
    setStateTaskList,
    getStateTaskList

}

function clearStorage() {    
    localStorage.removeItem('authToken');
    localStorage.removeItem('email');
    localStorage.removeItem('loginTime');
    localStorage.removeItem('userRole');    
    localStorage.removeItem('workTimesheet-gridList');    
    /*localStorage.clear();*/
}


function getAuthToken() {
    return localStorage.getItem('authToken');
}

function getEmail() {
    return localStorage.getItem('email');
}

function setAuthToken(authToken) {
    return localStorage.setItem('authToken', authToken);
}

function setEmail(email) {
    return localStorage.setItem('email', email);
}

function setLoginTime(time) {
    return localStorage.setItem('loginTime', time);
}

function getLoginTime() {
    return localStorage.getItem('loginTime');
}

function setUserRole(role) {
    return localStorage.setItem('userRole', role);
}

function getUserRole() {
    return localStorage.getItem('userRole');
}

function setStateTaskList(storageKey,state) {
    localStorage.setItem(storageKey, JSON.stringify(state));
}

function getStateTaskList(storageKey) {
    return JSON.parse(localStorage.getItem(storageKey));
}
