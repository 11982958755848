import * as Constants from '../constant/appConst';
import httpService from "./httpService";


export const chainService = {
    getChain,
};

async function getChain() {
    return await httpService.get(Constants.apiConst.ENDPOINT + Constants.apiConst.CHAINS, {});
}