import React, { useEffect, useRef, useState, setState, createRef } from 'react';
import HtmlEditor, { Toolbar, MediaResizing, Item } from 'devextreme-react/html-editor';
import List from 'devextreme-react/list';
import { Button } from 'devextreme-react/button';
import { taskCommentService } from '../../services/taskComment-service'
import notify from 'devextreme/ui/notify';
import { ScrollView } from 'devextreme-react';
import TaskCommentInfo from './TaskCommentInfo';
//import { Editor } from 'react-draft-wysiwyg';

//const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
//const fontValues = ['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana'];
//const headerValues = [false, 1, 2, 3, 4, 5];

const TaskComment = (props) => {
    const [isMultiline, setIsMultiline] = useState(true);
    const [dataSource, setDataSource] = useState({});
    const [text, setText] = useState('');
    const listRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        if (props.properties != null && props.properties.task) {
            console.log(props.properties.task);
            setIsMultiline(true);
            get(props.properties.task.id, props.properties.task.chainId);
            setIsLoading(false);
        }
    }, []);

    const updateScroll = () => {
        if (listRef.current != null) {
            let listItemNodes = document.getElementsByClassName("dx-list-item");
            listRef.current.instance.scrollToItem(listItemNodes[listItemNodes.length - 1]);
        }
    };

    const get = async (taskId, chainId) => {
        setIsLoading(true);
        if (props.properties.task.chainId == null) {
            toast("Please select chain!", "warning");
            setIsLoading(false);
            return;
        }

        let taskComments = await taskCommentService.get(taskId, chainId);
        if (taskComments) {
            setIsLoading(false);
            setDataSource(taskComments);
        }
    };

    const clear = () => {
        setText('');
    }

    const insert = async (event) => {        
        if (text && props.properties.task) {
            setIsLoading(true);

            const taskComment = {
                taskId: props.properties.task.id,
                text: text,
                chainId: props.properties.task.chainId
            };
            let insertedTaskComment = await taskCommentService.insert(taskComment);
            if (insertedTaskComment) {
                setText('');
                setDataSource(prevDataSource => {
                    return [...prevDataSource, insertedTaskComment];
                });
                setIsLoading(false);
               
            }
            get(props.properties.task.id, props.properties.task.chainId);
        }
    }
    const toast = (message, infoType) => {
        notify({
            message: message,
            position: {
                my: 'center top',
                at: 'center top'
            }
        }, infoType, 3000);
    }
    const textChanged = (e) => {
        setText(e.value);
    }

    return (
        <React.Fragment>
            <ScrollView
                id="scrollview1"
                scrollByContent={true}
                bounceEnabled={false}
                showScrollbar={'always'}
                scrollByThumb={true}
                width='97%'
                height='100%'
            >
                <div
                    id="task-comment-grid"
                    className="widget-list-container">
                    <List
                        dataSource={dataSource}
                        ref={listRef}
                        onItemRendered={updateScroll}
                        repaintChangesOnly={true}
                        itemRender={(e) => { return TaskCommentInfo(e, setIsLoading, toast, setDataSource, taskCommentService); }}
                        id="listComments"
                        pageLoadMode="scrollBottom"
                    >
                    </List>
                </div>

                <div id="task-editor">

                    <HtmlEditor
                        height='45%'
                        value={text}
                        onValueChanged={textChanged}
                    >
                        <MediaResizing enabled={true} />
                        <Toolbar multiline={isMultiline}>
                            {/*<Item name="undo" />*/}
                            {/*<Item name="redo" />*/}
                            {/*<Item name="separator" />*/}
                            {/*<Item*/}
                            {/*    name="size"*/}
                            {/*    acceptedValues={sizeValues}*/}
                            {/*/>*/}
                            {/*<Item*/}
                            {/*    name="font"*/}
                            {/*    acceptedValues={fontValues}*/}
                            {/*/>*/}
                            {/*<Item name="separator" />*/}
                            <Item name="bold" />
                            <Item name="italic" />
                            <Item name="strike" />
                            <Item name="underline" />
                            <Item name="separator" />
                            <Item name="alignLeft" />
                            <Item name="alignCenter" />
                            <Item name="alignRight" />
                            <Item name="alignJustify" />
                            <Item name="separator" />
                            <Item name="orderedList" />
                            <Item name="bulletList" />
                            <Item name="separator" />
                            {/*<Item*/}
                            {/*    name="header"*/}
                            {/*    acceptedValues={headerValues}*/}
                            {/*/>*/}
                            {/*<Item name="separator" />*/}
                            {/*<Item name="color" />*/}
                            {/*<Item name="background" />*/}
                            {/*<Item name="separator" />*/}
                            {/*<Item name="link" />*/}
                            {/*<Item name="image" />*/}
                            {/*<Item name="separator" />*/}
                            {/*<Item name="clear" />*/}
                            {/*<Item name="codeBlock" />*/}
                            {/*<Item name="blockquote" />*/}
                            {/*<Item name="separator" />*/}
                            {/*<Item name="insertTable" />*/}
                            {/*<Item name="deleteTable" />*/}
                            {/*<Item name="insertRowAbove" />*/}
                            {/*<Item name="insertRowBelow" />*/}
                            {/*<Item name="deleteRow" />*/}
                            {/*<Item name="insertColumnLeft" />*/}
                            {/*<Item name="insertColumnRight" />*/}
                            {/*<Item name="deleteColumn" />*/}
                        </Toolbar>
                    </HtmlEditor>
                    <div style={{ padding: '10px' }}>
                        <Button
                            width={120}
                            text="Clear"
                            type="normal"
                            stylingMode="outlined"
                            onClick={clear}
                            style={{ margin: '0 10px' }}
                        />
                        <Button
                            width={120}
                            text="Save"
                            type="success"
                            stylingMode="outlined"
                            onClick={insert}
                            style={{ margin: '0 10px' }}
                        />
                    </div>
                </div>
            </ScrollView>

        </React.Fragment>
    );
}

export default TaskComment;
