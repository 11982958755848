import 'devextreme/data/odata/store';
import CustomStore from 'devextreme/data/custom_store';
import { taskService } from '../services/task-service';
import { toast } from '../common/common';

export function taskDataSource(chainID) {

    return {
        store: new CustomStore({
            key: 'id',
            load: async (loadOptions) => {
                let params = '?';
                [
                    'skip',
                    'take',
                    'requireTotalCount',
                    'requireGroupCount',
                    'sort',
                    'filter',
                    'totalSummary',
                    'group',
                    'groupSummary'
                ].forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
                params = params.slice(0, -1);
                let taskData = await taskService.getTasks(params, chainID, 0);
                return {
                    data: taskData.data,
                    totalCount: taskData.totalCount,
                    summary: taskData.summary,
                    groupCount: taskData.groupCount
                };
            },
            insert: async (values) => {
                if (values != null) {
                    if (chainID != -1) {
                        values.chainId = chainID;
                    }
                    let taskData = await taskService.addTask(values);
                    toast("Task is created ", "success");
                }

            },

            update: async (key, values) => {
                values.id = key;
                if (key > 0) {
                    if (chainID != -1) {
                        values.chainId = chainID;
                    }
                    await taskService.updateTask(values);
                    toast("Task is updated ", "success");
                }
            },
            remove: async (key) => {
                if (key > 0) {
                    await taskService.deleteTask(key);
                    toast("Task is deleted ", "success");
                }
            },
        })
    }
}