import defaultUser from '../utils/default-user';
import { localStorageService } from '../services/localstorage-service';

var dummyUser = {
  name: 'Ove',
  email: 'ove@touchsoft.no'
}

export async function signIn(email, password) {

  return {
    isOk: true,
    data: dummyUser
  };

}

export function getUser() {

  var userData = localStorageService.getLoginTime();
  if (userData) {
      return {
        isOk: true,
        data: userData,
        email : localStorageService.getEmail()
      };
  }

  return {
    isOk: false,
    data: null
  };
}

export async function createAccount(email, password) {
  try {
    // Send request
    console.log(email, password);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to create account"
    };
  }
}

export async function changePassword(email, recoveryCode) {
  try {
    // Send request
    console.log(email, recoveryCode);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to change password"
    }
  }
}

export async function resetPassword(email) {
  try {
    // Send request
    console.log(email);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to reset password"
    };
  }
}
