import * as Constants from '../constant/appConst'
import httpService from './httpService';


export const workTimesheetService = {
    getWorkHour,
    addWorkHour,
    deleteWorkHour,
    updateWorkHour
}

async function getWorkHour(param, taskId) {
    return await httpService.get(Constants.apiConst.ENDPOINT + Constants.apiConst.WORK_TIMESHEET_TASK + param + "&taskId=" + taskId, {})
}

async function addWorkHour(workHour) {
    return await httpService.post(Constants.apiConst.ENDPOINT + Constants.apiConst.WORK_TIMESHEET, workHour, {})
}

async function updateWorkHour(workHour) {
    return await httpService.put(Constants.apiConst.ENDPOINT + Constants.apiConst.WORK_TIMESHEET, workHour, {})
}

async function deleteWorkHour(workId) {
    let path = Constants.apiConst.ENDPOINT + Constants.apiConst.REMOVE_WORK_TIMESHEET;
    path = path.replace("{workId}", workId);
    return await httpService.delete(path, {})
}