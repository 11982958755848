import { withNavigationWatcher } from './contexts/navigation';
import { HomePage, TasksPage, TasksOldPage, ProfilePage, GanttPage, SchedulerPage } from './pages';

const routes = [
    {
        path: '/tasks',
        component: TasksPage
    },
    {
        path: '/gantt',
        component: GanttPage
    },
    {
        path: '/scheduler',
        component: SchedulerPage
    },
    {
        path: '/tasksOld',
        component: TasksOldPage
    },
    {
        path: '/profile',
        component: ProfilePage
    },
    {
        path: '/home',
        component: HomePage
    }
];

export default routes.map(route => {
    return {
        ...route,
        component: withNavigationWatcher(route.component)
    };
});
